<template>
  <div class="card">
  <Breadcrumb :model="items"/>

  <div class="p-mt-3" style="width: 100%">
    <DataTable ref="dt" :value="messageList" v-model:selection="selectedProducts" dataKey="id"
               :auto-layout="true"
               :paginator="true" :rows="10"
               paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
               :rowsPerPageOptions="[5,10,25]"
               currentPageReportTemplate="显示 {first} 到 {last} 条记录，总计{totalRecords}记录">
      <template #header>
        <div class="p-d-flex p-col">
          <InputText v-model="title" placeholder="消息主题"/>
          <Dropdown class="p-ml-3" v-model="name" :options="people" optionLabel="name" optionValue="id"
                    placeholder="发布人"
                    :showClear="true"/>
          <Dropdown class="p-ml-3"
                    v-model="industry"
                    :options="BelongIndustry" optionLabel="name" option-value="name"
                    placeholder="所属行业"
                    :showClear="true"/>
          <Calendar onfocus=this.blur() style="width: 22.5%" class="p-ml-3"
                    v-model="time" placeholder="发布日期"
                    @clear-click="showMessageList"
                    dateFormat="yy-mm-dd" showButtonBar="true"/>
          <Button class="p-ml-2" @click="showMessageList">查询</Button>
          <Button @click="showInvitePeople" :class="{'p-disabled':selectedProducts.length==0}" label="消息接收人员"
                  class="p-ml-2"/>
        </div>
      </template>
      <Column selectionMode="multiple"></Column>
      <Column field="id" header="序号" :sortable="true"></Column>
      <Column :sortable="false" field="title" header="消息主题"></Column>
      <Column field="time" header="发布时间" :sortable="true" headerStyle="width: 12rem">
        <template #body="a">
          {{
            new Date(+new Date(a.data.time) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
          }}
        </template>
      </Column>
      <Column field="allUser" header="全员消息" :sortable="true">
        <template #body="a">
          {{ a.data.allUser == 1 ? '全员消息' : '部分消息' }}
        </template>
      </Column>
      <Column field="name" header="发布人" :sortable="false"></Column>
      <Column field="industry" header="所属行业" :sortable="false"></Column>
      <Column :exportable="false" field="operation" header="操作" headerStyle="width:25%;">
        <template #body="slotProps">
          <Button class="p-button-sm p-m-1" @click="goto(slotProps.data.id)">查看</Button>
          <Button :class="{'p-disabled':slotProps.data.allUser==1}" class="p-button-sm p-m-1"
                  @click="gotoView(slotProps.data)">接收人员列表
          </Button>
          <Button class="p-button-danger p-button-sm p-m-1" @click="confirmDeleteProduct(slotProps.data)">删除</Button>
        </template>
      </Column>
    </DataTable>
  </div>
  <Dialog v-model:visible="deleteProductDialog" :style="{width: '350px'}" header="确认" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem"/>
      <span v-if="product">你确认要删除 <b>{{ product.title }}</b>?</span>
    </div>
    <template #footer>
      <Button label="否" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
      <Button label="是" icon="pi pi-check" class="p-button-text" @click="deleteProduct(product.id)"/>
    </template>
  </Dialog>
  <Toast/>
    <InvitePeople v-on:close="show=false" v-if="show" ref="InvitePeople"></InvitePeople>
  </div>
</template>

<script>
import InvitePeople from "@/views/modules/public/InvitePeople";
export default {
  components:{InvitePeople},
  mounted() {
    this.showMessageList()
    this.adminList()
    this.getIndustry()
  },
  name: "",
  data() {
    return {
      peopleList:[],
      title: null,
      name: null,
      industry: null,
      time: null,
      messageList: [],
      people: [],
      BelongIndustry: [],
      items: [
        {label: '消息推送', to: '/MessageList'},
        {label: '消息发送列表', to: '/MessageList'}
      ],
      deleteProductDialog: false,
      product: {},
      selectedProducts: [],
      filters: {},
      show:false
    }
  },

  methods: {
    showInvitePeople(){
      this.show=true
      this.$nextTick(()=>{
        this.$refs.InvitePeople.init(3,this.selectedProducts)
      })
    },

    getIndustry(){
      this.$http.get('/dictionary/selectValue',{params:{key:'suoshuhangye'}}).then((res)=>{
        let list =res.data.myValue.split(",");
        let resList=[]
        for(let i in list){
          let item ={}
          item.name=list[i]
          resList.push(item)
        }
        console.log(resList)
        this.BelongIndustry=resList;
      })
    },
    adminList() {
      this.$http.get('/adminUser/getList', {
        params: {
          name: ''
        }
      }).then((res) => {
        this.people = res.data
        console.log(this.people)
      })

    },
    goto(id) {
      this.$router.push('/newsDetails?id=' + id)
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct(id) {
      this.$http.get('/send/remove', {params: {'id': id}}).then((res) => {
        this.deleteProductDialog = false;
        this.plan = res.data
        this.$toast.add({severity: 'success', summary: '成功', detail: '删除成功', life: 3000});
        this.showMessageList()
      })
    },
    showMessageList() {
      console.log(this.time)
      this.$http.get('/send/sendMessageList', {
        params: {
          state: 0,
          title: this.title,
          addUser: this.name,
          industry: this.industry,
          addTime: this.time,
        }
      }).then((res) => {
        this.messageList = res.data
        console.log(res.data)
      })
    },
    termQuery() {
      let time = new Date(this.time == "" ? 1970 - 1 - 1 : this.time)
      time = time.getFullYear() + '-' + (time.getMonth() + 1) + '-' + time.getDate();
      this.$http.get('/send/termQuery', {
        params: {
          'title': this.title,
          'name': this.name,
          'industry': this.industry,
          'time': time
        }
      }).then((res) => {
        this.messageList = res.data
        this.$toast.add({severity: 'success', summary: '成功', detail: '查询成功', life: 3000});
      })
    },

    gotoView(data) {
      this.$router.push('/participants?id=' + data.id + '&tableType=3')
    },

  }

}
</script>

<style>
.p-datatable .p-datatable-footer{
  display: flex;
  justify-content: flex-end;
}
</style>